<div *ngIf="callmeService.isOpened()" class="callme">
    <div class="card">
        <div class="card-body pt-0">
            <button (click)="callmeService.close()" type="button"
                    class="close float-right" aria-label="Close">
                <i class="far fa-times-circle"></i>
            </button>
            <h5 class="card-title">{{ callmeService.title }}</h5>
            <p *ngIf="callmeForm.controls.state.value == 'submitted'" class="card-text text-center mt-5">
                Ваша заявка отправлена.<br>
                Наш менеджер свяжется с Вами в ближайшее время.
            </p>
            <div *ngIf="callmeForm.controls.state.value != 'submitted'">
                <p class="card-text text-center">
                    Оставьте свой номер телефона и мы свяжемся с Вами в ближайшее время!
                </p>
                <form [formGroup]="callmeForm" (ngSubmit)="onCallmeFormSubmit()">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="+7"
                               formControlName="phone">
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="callme-popup-privacy"
                               formControlName="privacy">
                        <label class="form-check-label text-justify" for="callme-popup-privacy">
                            Я согласен на обработку персональных данных в соответствии с
                            <a routerLink="privacy">условиями политики конфиденциальности</a>
                        </label>
                    </div>
                    <div class="text-center mt-3 mb-3">
                        <button type="submit" class="btn btn-like"
                                [disabled]="!callmeForm.valid || callmeForm.controls.state.value == 'submitting'">
                            Заказать звонок
                        </button>
                    </div>
                </form>
                <small class="card-text text-muted text-center d-block">
                    Никакие из предоставленных Вами данных не будут переданы третьим лицам.
                </small>
            </div>
        </div>
    </div>
</div>

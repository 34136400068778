<nav>
    <a *ngIf="navbarService.useAbsoluteLinks" href="https://likehostels.ru#about">О хостеле</a>
    <a *ngIf="!navbarService.useAbsoluteLinks" routerLink="/" fragment="about">О хостеле</a>
    <div class="likehostel-logo-navbar">
        <a *ngIf="navbarService.useAbsoluteLinks" class="likehostel-logo" href="https://likehostels.ru"></a>
        <a *ngIf="!navbarService.useAbsoluteLinks" class="likehostel-logo" routerLink="/"></a>
    </div>
    <a href="https://likehostels.ru/franshiza/?source=site">Франчайзинг</a>
</nav>
<div class="navbar-overlay"></div>

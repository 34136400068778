import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Error404Module } from "error404/error404.module";
import { BrowserModule, BrowserTransferStateModule } from "@angular/platform-browser";
import { CityComponent } from "./city.component";
import { CityRoutingModule } from "./city-routing.module";
import { CityModule } from "city/city.module";
import { BootstrapModule } from "app/bootstrap/bootstrap.module";
import { LayoutModule } from "layout/layout.module";
import { AnalyticsModule } from "analytics/analytics.module";

@NgModule({
    id: 'city-browser',
    imports: [
        BrowserModule.withServerTransition({appId: 'city'}),
        BrowserTransferStateModule,
        CommonModule,
        BootstrapModule, LayoutModule, AnalyticsModule,
        CityRoutingModule,
        CityModule,
        Error404Module
    ],
    declarations: [
        CityComponent
    ],
    bootstrap: [CityComponent]
})
export class CityBrowserModule {
}

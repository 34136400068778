import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params as  RouterParams } from "@angular/router";
import { BookingService } from "./booking.service";
import { BootstrapData } from "app/bootstrap/bootstrap.service";
import { merge } from 'lodash';

@Component({
    selector: 'hostel-booking',
    templateUrl: './booking.component.html',
    providers: [BookingService]
})
export class BookingComponent implements OnInit {

    public constructor(
        protected bootstrapData: BootstrapData,
        protected route: ActivatedRoute,
        protected bookingService: BookingService
    ) {
    }

    public ngOnInit(): void {
        this.route.params.subscribe(() => {
            this.setRouteParams(merge({}, this.route.snapshot.params, this.route.snapshot.queryParams));
        });
    }

    protected setRouteParams(params: RouterParams): void {
        this.bookingService.init(
            params.dateFrom,
            params.dateTo,
            params.promo,
            params.roomId
        );
    }

    public isBnovo(): boolean {
        return this.bootstrapData.hostel.booking.provider == 'bnovo';
    }

    public isWubook(): boolean {
        return this.bootstrapData.hostel.booking.provider == 'wubook';
    }

}

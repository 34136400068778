<hostel-index-carousel
        [photos]="data.photos"
        [promoTitle]="data.promoTitle"
        (bookingFormSubmit)="onBooking()"
></hostel-index-carousel>

<a name="rooms" class="navbar-skipped-anchor"></a>
<div class="hostel-index-rooms pt-4 pb-5">
    <div class="pb-3 hostel-rooms-list">
        <h2 class="mb-3">Номера и цены</h2>
        <hostel-room *ngFor="let room of data.rooms; trackBy: trackByRoom"
                     (booking)="onBooking($event)"
                     [room]="room">
        </hostel-room>
    </div>
    <div *ngIf="data.promos.length > 0" class="container hostel-promos">
        <div *ngFor="let promo of data.promos; trackBy: trackByPromo"
             [style.background-image]="'url(' + getPromoImg(promo.promoId) + ')'" class="hostel-promo">
            <div class="hostel-promo-bg">
                <h5>{{ promo.title }}</h5>
                <p>{{ promo.body }}</p>
            </div>
        </div>
    </div>
    <div *ngIf="data.reviews.length > 0" class="container mt-5 pb-5">
        <hostel-reviews-list [reviews]="data.reviews"></hostel-reviews-list>
    </div>
    <hostel-index-ota-widgets></hostel-index-ota-widgets>
</div>

<hostel-whats-in></hostel-whats-in>

<city-description *ngIf="data.city" [city]="data.city"></city-description>

<a name="contacts" class="navbar-skipped-anchor"></a>
<hostel-index-address [coordinates]="data.coordinates" [hostel]="bootstrapData.hostel"></hostel-index-address>

import {
    AfterViewInit,
    Directive,
    ElementRef,
    Inject,
    Input,
    NgZone, OnDestroy,
    PLATFORM_ID
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Subscription } from "rxjs";

@Directive({
    selector: 'input[datepicker]'
})
export class DatepickerDirective implements AfterViewInit, OnDestroy {

    @Input('datepicker')
    public options: any;

    protected onStableSubscription: Subscription;

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected element: ElementRef,
        protected ngZone: NgZone
    ) {
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.ngZone.runOutsideAngular(() => {
                let nativeElement = this.element.nativeElement as HTMLInputElement;
                let element = jQuery(nativeElement);
                let oldValue = element.val();
                element.datepicker(this.options).on('change', () => {
                    if (element.val() != oldValue) {
                        oldValue = element.val();
                        nativeElement.dispatchEvent(new Event("input"));
                    }
                });
                this.onStableSubscription = this.ngZone.onStable.subscribe(() => {
                    if (element.val() != oldValue) {
                        oldValue = element.val();
                        element.datepicker('update');
                    }
                });
            });
        }
    }

    public ngOnDestroy(): void {
        if (this.onStableSubscription) {
            this.onStableSubscription.unsubscribe();
        }
        if (isPlatformBrowser(this.platformId)) {
            jQuery(this.element.nativeElement).datepicker('destroy');
        }
    }

    @Input('startDate')
    public set startDate(startDate: Date | string) {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                jQuery(this.element.nativeElement).datepicker('setStartDate', startDate);
            }, 0);
        }
    }

    @Input('endDate')
    public set endDate(endDate: Date | string) {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                jQuery(this.element.nativeElement).datepicker('setEndDate', endDate);
            }, 0);
        }
    }

}

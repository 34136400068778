import { Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { City } from "../city";
import './city-description.component.scss';
import { GlobalConfig } from "app/bootstrap/global-config";

@Component({
    selector: 'city-description',
    templateUrl: './city-description.component.html'
})
export class CityDescriptionComponent implements OnChanges {

    @ViewChild('cityDescription')
    public cityDescription: ElementRef;

    @Input('city')
    public city: City;

    public constructor(
        protected globalConfig: GlobalConfig,
    ) {
    }

    public ngOnChanges(): void {
        this.cityDescription.nativeElement.innerHTML = this.city.description;
    }

    public getCityImg(): string {
        return this.globalConfig.content.url + this.city.img;
    }

}

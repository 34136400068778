import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter,
    Inject, Input,
    NgZone,
    OnDestroy, Output,
    PLATFORM_ID,
    ViewChild
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { IndexDataPhoto } from "./index.component";
import { GlobalConfig } from "app/bootstrap/global-config";
import './carousel.component.scss';

@Component({
    selector: 'hostel-index-carousel',
    templateUrl: './carousel.component.html'
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
    @ViewChild('photosCarousel')
    public photosCarousel: ElementRef;

    @Input('photos')
    public photos: IndexDataPhoto[] = [];

    @Input('promoTitle')
    public promoTitle: string;

    @Output('bookingFormSubmit')
    public bookingFormSubmit: EventEmitter<void> = new EventEmitter<void>();

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected globalConfig: GlobalConfig,
        protected ngZone: NgZone,
        protected changeDetector: ChangeDetectorRef
    ) {
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.ngZone.runOutsideAngular(() => {
                let photosCarousel = jQuery(this.photosCarousel.nativeElement);
                photosCarousel.carousel({
                    interval: 5000,
                    pause: false
                });
                photosCarousel.on('slide.bs.carousel', () => {
                    this.changeDetector.detectChanges();
                });
                photosCarousel.on('slid.bs.carousel', () => {
                    this.changeDetector.detectChanges();
                });
            });
        }
    }

    public ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            let photosCarousel = jQuery(this.photosCarousel.nativeElement);
            photosCarousel.off('slide.bs.carousel');
            photosCarousel.off('slid.bs.carousel');
        }
    }

    public trackByPhoto(index: number, photo: IndexDataPhoto): string {
        return photo.full;
    }

    public showPhotoSlide(number: number): void {
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.photosCarousel.nativeElement).carousel(number);
        });
    }

    public getPhotoSlideCurrentNumber(): number {
        if (!isPlatformBrowser(this.platformId)) {
            return 0;
        }
        let inner = jQuery(this.photosCarousel.nativeElement).children('.carousel-inner');
        let number = inner.children('.carousel-item-next, .carousel-item-prev').index();
        if (number > -1) {
            return number;
        }
        return inner.children('.active').index();
    }

    public getPhotoImg(url: string): string {
        return this.globalConfig.content.url + url;
    }

}

import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { FormControl, FormGroup, Validators, ValidationErrors, AbstractControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import './callme.component.scss';
import { CallmeService } from "./callme.service";
import { BootstrapData } from "app/bootstrap/bootstrap.service";

@Component({
    selector: 'hostel-callme',
    templateUrl: './callme.component.html'
})
export class CallmeComponent implements OnInit {

    public callmeForm = new FormGroup({
        phone: new FormControl(null, [
            Validators.required,
            Validators.minLength(5),
            Validators.pattern(/^(\.|\+|-|[0-9])+$/)
        ]),
        privacy: new FormControl(null, [
            Validators.required,
            (control: AbstractControl): ValidationErrors | null => {
                if (control.value == true) {
                    return null;
                }
                return {privacy: 'This field is required'};
            }
        ]),
        state: new FormControl()
    });

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected bootstrapData: BootstrapData,
        protected httpClient: HttpClient,
        public callmeService: CallmeService,
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        this.callmeService.onClose.subscribe(() => {
            this.callmeForm.reset({
                phone: '',
                privacy: false,
                state: null
            });
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.callmeService.close();
            }
        });
    }

    public onCallmeFormSubmit(): void {
        this.callmeForm.get('state').setValue('submitting');
        this.httpClient.post('/v1/feedback/callme', {
            hostel_id: this.bootstrapData.hostel.hostelId,
            phone: this.callmeForm.get('phone').value
        }).toPromise()
            .then(() => {
                this.callmeForm.get('state').setValue('submitted');
            })
            .catch((error: Error) => {
                console.error('Callme error:', error);
                this.callmeForm.get('state').setValue(null);
            });
    }

}

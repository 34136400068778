import { Component, Input } from "@angular/core";
import { HostelReview } from "../hostel";
import './reviews-list.component.scss';

@Component({
    selector: 'hostel-reviews-list',
    templateUrl: './reviews-list.component.html'
})
export class ReviewsListComponent {

    @Input('reviews')
    public reviews: HostelReview[] = [];

    public reviewsMaxlength = 6;

    public trackByReview(index: number, review: HostelReview): number {
        return review.id;
    }

}

import { NgModule } from "@angular/core";
import { YandexMapsService } from "./yandex-maps.service";

@NgModule({
    imports: [],
    providers: [YandexMapsService],
    exports: []
})
export class YandexMapsModule {
}

import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { YandexMapsService } from "yandex-maps/yandex-maps.service";
import { IndexDataCoordinates } from "./index.component";
import './address.component.scss';

@Component({
    selector: 'hostel-index-address',
    templateUrl: './address.component.html'
})
export class AddressComponent implements AfterViewInit, OnDestroy {

    @ViewChild('contactMap')
    public contactMap: ElementRef;

    protected contactYaMap: ymaps.Map;

    @ViewChild('contactCard')
    public contactCard: ElementRef;

    @Input('coordinates')
    public coordinates: IndexDataCoordinates;

    @Input('hostel')
    public hostel: {
        name: string,
        address: string,
        phone: string
    };

    public constructor(
        protected ngZone: NgZone,
        protected yaMapsService: YandexMapsService
    ) {
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.yaMapsService.createMap(this.contactMap, {
                center: [this.coordinates.latitude, this.coordinates.longitude],
                zoom: 13,
                controls: ['geolocationControl', 'zoomControl']
            }).then((map) => {
                this.contactYaMap = map;
                let hostelPlacemark = new ymaps.Placemark(
                    [this.coordinates.latitude, this.coordinates.longitude],
                    {hintContent: this.hostel.name},
                    {preset: 'islands#redHotelIcon'}
                );
                map.geoObjects.add(hostelPlacemark);
                hostelPlacemark.events.add('click', () => {
                    map.setCenter(
                        [this.coordinates.latitude, this.coordinates.longitude],
                        17,
                        {duration: 500}
                    );
                });
                jQuery(this.contactCard.nativeElement).on('click', () => {
                    map.setCenter(
                        [this.coordinates.latitude, this.coordinates.longitude],
                        17,
                        {duration: 500}
                    );
                });
            });
        });
    }

    public ngOnDestroy(): void {
        if (this.contactYaMap) {
            this.contactYaMap.destroy();
        }
    }

}

import { NgModule } from "@angular/core";
import { BrowserModule, BrowserTransferStateModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { NotFoundComponent } from "./notfound.component";
import { NotFoundRoutingModule } from "./notfound-routing.module";
import { Error404Module } from "error404/error404.module";
import { BootstrapModule } from "app/bootstrap/bootstrap.module";
import { LayoutModule } from "layout/layout.module";

@NgModule({
    id: 'notfound-browser',
    imports: [
        BrowserModule.withServerTransition({appId: 'hostel-notfound'}),
        BrowserTransferStateModule,
        CommonModule,
        BootstrapModule, LayoutModule,
        NotFoundRoutingModule,
        Error404Module
    ],
    declarations: [NotFoundComponent],
    bootstrap: [NotFoundComponent]
})
export class NotFoundBrowserModule {
}

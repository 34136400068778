import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { IndexComponent, IndexResolver } from "./index/index.component";
import { BookingComponent } from "./booking/booking.component";
import { AboutComponent, AboutResolver } from "./about/about.component";

const routes: Routes = [
    {
        path: '',
        component: IndexComponent,
        resolve: {
            component: IndexResolver
        },
        data: {
            navbar: {useAbsoluteLinks: true}
        }
    },
    {
        path: 'booking',
        component: BookingComponent,
        data: {
            footer: {
                showInLayout: false
            }
        }
    },
    {
        path: 'about',
        component: AboutComponent,
        resolve: {
            component: AboutResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [
        IndexResolver, AboutResolver
    ],
    exports: [RouterModule]
})
export class HostelRoutingModule {
}

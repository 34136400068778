import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { BootstrapData, BootstrapType } from "app/bootstrap/bootstrap.service";

export interface Ota101Hotels {
    readonly generatedId: number;
    readonly id: number;
    readonly link: string;
}

@Component({
    selector: 'hostel-index-ota-widgets',
    templateUrl: './ota-widgets.component.html'
})
export class OtaWidgetsComponent implements OnInit, AfterViewInit, OnDestroy {
    public ota101Hotels: Ota101Hotels | null = null;
    protected ota101hotelsScript: HTMLScriptElement | null = null;

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        @Inject(DOCUMENT) protected document: HTMLDocument,
        protected bootstrapData: BootstrapData
    ) {
    }

    public ngOnInit(): void {
        if (this.bootstrapData.type == BootstrapType.HOSTEL && this.bootstrapData.hostel.alias == 'spb1') { // @todo: hardcode!
            this.ota101Hotels = {
                generatedId: 803,
                id: 14287,
                link: 'https://www.101hotels.ru/main/cities/sankt-peterburg/hostel_layk_na_teatralnoy.html'
            };
        }
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId) && this.ota101Hotels) {
            this.ota101hotelsScript = this.resolve101hotelsScript();
        }
    }

    public ngOnDestroy(): void {
        if (this.ota101hotelsScript) {
            this.ota101hotelsScript.remove();
        }
    }

    public get101hotelsHrefId(): string | null {
        if (!this.ota101Hotels) {
            return null;
        }
        return 'hotels101_partner_widget_' + this.ota101Hotels.generatedId;
    }

    protected resolve101hotelsScript(): HTMLScriptElement | null {
        if (!this.ota101Hotels) {
            return null;
        }
        let src = 'https://www.101hotels.ru/widget/generator/' + this.ota101Hotels.generatedId + '/' + this.ota101Hotels.id + '/t-4/without-hover';
        let script: HTMLScriptElement = null;
        let docScripts = this.document.getElementsByTagName('script');
        for (let i = docScripts.length; i--;) {
            if (docScripts[i].src == src) {
                script = docScripts[i];
                break;
            }
        }
        if (!script) {
            script = this.document.createElement('script');
            script.async = true;
            script.src = src;
            script.type = 'text/javascript';
            this.document.body.appendChild(script);
        }
        return script;
    }

}

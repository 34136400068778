import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule, BrowserTransferStateModule } from "@angular/platform-browser";
import { Error404Module } from "error404/error404.module";
import { HostelComponent } from "./hostel.component";
import { HostelRoutingModule } from "./hostel-routing.module";
import { BootstrapModule } from "app/bootstrap/bootstrap.module";
import { HostelModule } from "hostel/hostel.module";
import { LayoutModule } from "layout/layout.module";
import { AnalyticsModule } from "analytics/analytics.module";
import { FeedbackModule } from "feedback/feedback.module";
import { DocumentsModule } from "documents/documents.module";
import { VacancyHotelModule } from "vacancy/vacancy.module";

@NgModule({
    id: 'hostel-browser',
    imports: [
        BrowserModule.withServerTransition({appId: 'hostel'}),
        BrowserTransferStateModule,
        CommonModule,
        BootstrapModule, LayoutModule, AnalyticsModule, FeedbackModule,
        HostelRoutingModule,
        HostelModule,
        DocumentsModule,
        VacancyHotelModule,
        Error404Module
    ],
    declarations: [
        HostelComponent
    ],
    bootstrap: [HostelComponent]
})
export class HostelBrowserModule {
}

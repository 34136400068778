import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DummyRoutingModule } from "./dummy-routing.module";
import { DocumentsModule } from "documents/documents.module";
import { IndexComponent } from "./index/index.component";
import { CityCommonModule } from "city/city-common.module";
import { HostelCommonModule } from "hostel/hostel-common.module";
import { YandexMapsModule } from "yandex-maps/yandex-maps.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports: [
        CommonModule, ReactiveFormsModule,
        DummyRoutingModule,
        YandexMapsModule,
        CityCommonModule, HostelCommonModule,
        DocumentsModule
    ],
    declarations: [
        IndexComponent
    ],
    exports: [
        DummyRoutingModule
    ]
})
export class DummyModule {
}

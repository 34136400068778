import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import './bnovo.component.scss';
import { BookingService } from "./booking.service";
import * as moment from "moment";
import { BootstrapData } from "app/bootstrap/bootstrap.service";

@Component({
    selector: 'hostel-bnovo',
    templateUrl: './bnovo.component.html'
})
export class BnovoComponent implements OnInit {

    @ViewChild('bnovoFrame')
    public bnovoFrame: ElementRef;

    public constructor(
        protected bootstrapData: BootstrapData,
        protected bookingService: BookingService
    ) {
    }

    public ngOnInit(): void {
        let bnovoFrame: HTMLIFrameElement = this.bnovoFrame.nativeElement;
        let bnovoFrameSrc = 'https://reservationsteps.ru/rooms/index/' + this.bootstrapData.hostel.booking.bnovo.uid + '?';
        if (this.bookingService.hasDateFrom()) {
            bnovoFrameSrc = bnovoFrameSrc + '&dfrom=' + moment(this.bookingService.getDateFrom()).format('DD-MM-YYYY');
        }
        if (this.bookingService.hasDateTo()) {
            bnovoFrameSrc = bnovoFrameSrc + '&dto=' + moment(this.bookingService.getDateTo()).format('DD-MM-YYYY');
        }
        bnovoFrame.src = bnovoFrameSrc;
    }

}

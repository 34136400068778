import { EventEmitter, Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { isDate, isString } from "util";
import { isMoment } from "moment";

@Injectable()
export class BookingService {

    protected dateFrom?: Date = null;
    protected dateTo?: Date = null;
    protected promoCode?: string = null;
    protected roomId?: number = null;

    public changes: EventEmitter<{
        dateFrom?: Date,
        dateTo?: Date,
        promoCode?: string,
        roomId?: number
    }> = new EventEmitter();

    public constructor(
        protected domSanitizer: DomSanitizer
    ) {
    }

    protected normalizeDate(date: Date | string | moment.Moment, format: string = 'YYYY-MM-DD'): Date | null {
        if (isDate(date)) {
            return date;
        }
        if (isString(date)) {
            date = moment(date, format);
        }
        if (isMoment(date)) {
            if (!date.isValid()) {
                return null;
            }
            return date.toDate();
        }
        return null;
    }

    protected normalizeDates(dateFrom: Date | null, dateTo: Date | null): {
        dateFrom: Date | null,
        dateTo: Date | null
    } {
        if (!dateFrom && dateTo) {
            dateFrom = moment(dateTo).subtract(1, 'd').toDate();
        } else if (dateFrom && !dateTo) {
            dateTo = moment(dateFrom).add(1, 'd').toDate();
        }
        if (dateFrom && dateTo) {
            if (moment(dateFrom).isBefore(new Date(), 'date')) {
                dateFrom = new Date();
            }
            if (moment(dateTo).isSameOrBefore(new Date(), 'date')) {
                dateTo = moment(new Date()).add(1, 'd').toDate();
            }
            dateFrom.setHours(0);
            dateFrom.setMinutes(0);
            dateFrom.setSeconds(0);
            dateTo.setHours(0);
            dateTo.setMinutes(0);
            dateTo.setSeconds(0);
            if (moment(dateTo).isSameOrBefore(dateFrom, 'date')) {
                dateTo = moment(dateFrom).add(1, 'd').toDate();
            }
        }
        return {
            dateFrom: dateFrom,
            dateTo: dateTo
        };
    }

    public setDateFrom(date: Date | string | moment.Moment): void {
        date = this.normalizeDate(date);
        let nd = this.normalizeDates(date, this.getDateTo());
        this.dateFrom = nd.dateFrom;
        this.dateTo = nd.dateTo;
        this.emitChanges();
    }

    public getDateFrom(): Date | null {
        return this.dateFrom;
    }

    public hasDateFrom(): boolean {
        return !!this.dateFrom;
    }

    public clearDateFrom(): void {
        this.dateFrom = null;
        this.emitChanges();
    }

    public setDateTo(date: Date | string | moment.Moment): void {
        date = this.normalizeDate(date);
        let nd = this.normalizeDates(this.getDateFrom(), date);
        this.dateFrom = nd.dateFrom;
        this.dateTo = nd.dateTo;
        this.emitChanges();
    }

    public getDateTo(): Date | null {
        return this.dateTo;
    }

    public hasDateTo(): boolean {
        return !!this.dateTo;
    }

    public clearDateTo(): void {
        this.dateTo = null;
        this.emitChanges();
    }

    protected normalizePromoCode(promoCode: string): string | null {
        if (isString(promoCode)) {
            promoCode = promoCode.trim();
            promoCode = this.domSanitizer.sanitize(SecurityContext.URL, promoCode);
            if (promoCode.length > 0) {
                return promoCode;
            }
        }
        return null;
    }

    public setPromoCode(promoCode: string): void {
        this.promoCode = this.normalizePromoCode(promoCode);
        this.emitChanges();
    }

    public getPromoCode(): string | null {
        return this.promoCode;
    }

    public hasPromoCode(): boolean {
        return !!this.promoCode;
    }

    public clearPromoCode(): void {
        this.promoCode = null;
        this.emitChanges();
    }

    public setRoomId(roomId: number): void {
        if (roomId) {
            this.roomId = Number(roomId);
        } else {
            this.roomId = null;
        }
        this.emitChanges();
    }

    public getRoomId(): number | null {
        return this.roomId;
    }

    public hasRoomId(): boolean {
        return !!this.roomId;
    }

    public clearRoomId(): void {
        this.roomId = null;
        this.emitChanges();
    }

    public init(
        dateFrom?: Date | string | moment.Moment,
        dateTo?: Date | string | moment.Moment,
        promoCode?: string,
        roomId?: number
    ): void {
        let nd = this.normalizeDates(this.normalizeDate(dateFrom), this.normalizeDate(dateTo));
        this.dateFrom = nd.dateFrom;
        this.dateTo = nd.dateTo;
        if (roomId) {
            this.roomId = Number(roomId);
        } else {
            this.roomId = null;
        }
        this.promoCode = this.normalizePromoCode(promoCode);
        this.emitChanges();
    }

    protected emitChanges(): void {
        this.changes.emit({
            dateFrom: this.getDateFrom(),
            dateTo: this.getDateTo(),
            promoCode: this.getPromoCode(),
            roomId: this.getRoomId()
        });
    }

}

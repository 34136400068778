import { Component, Inject, Injectable, NgZone, OnInit, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { BootstrapData, BootstrapService } from "app/bootstrap/bootstrap.service";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { MetaService } from "app/bootstrap/meta.service";
import { GlobalConfig } from "app/bootstrap/global-config";
import './index.component.scss';
import { City } from "city/city";
import { HostelReview, HostelRoom } from "hostel/hostel";
import * as moment from 'moment';
import { BookingService } from "hostel/booking/booking.service";
import { map } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";

export class IndexDataPage {

    @JsonProperty()
    public readonly description: string;

    @JsonProperty()
    public readonly keywords: string;

}

export class IndexDataPhoto {
    @JsonProperty()
    public readonly full: string;

    @JsonProperty()
    public readonly small: string;
}

export class IndexDataCoordinates {
    @JsonProperty()
    public readonly latitude: number;

    @JsonProperty()
    public readonly longitude: number;
}

export class IndexDataPromo {
    @JsonProperty({name: 'promo_id'})
    public readonly promoId: number;

    @JsonProperty()
    public readonly title: string;

    @JsonProperty()
    public readonly body: string;
}

export class IndexData {

    @JsonProperty({type: IndexDataPage})
    public readonly page: IndexDataPage;

    @JsonProperty({name: 'promo_title'})
    public readonly promoTitle: string;

    @JsonProperty({type: City})
    public readonly city: City;

    @JsonProperty({type: HostelReview})
    public readonly reviews: HostelReview[];

    @JsonProperty({type: HostelRoom})
    public readonly rooms: HostelRoom[];

    @JsonProperty({type: IndexDataPhoto})
    public readonly photos: IndexDataPhoto[];

    @JsonProperty({type: IndexDataCoordinates})
    public readonly coordinates: IndexDataCoordinates;

    @JsonProperty({type: IndexDataPromo})
    public readonly promos: IndexDataPromo[];
}

@Injectable()
export class IndexResolver implements Resolve<IndexData> {

    protected readonly TRANSFER_STATE_KEY = 'HostelIndexResolverData';

    public constructor(
        protected bootstrapService: BootstrapService,
        protected httpClient: HttpClient,
        protected transfaerStateService: TransferStateService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IndexData> {
        return this.transfaerStateService.get<IndexData>(this.TRANSFER_STATE_KEY, () => {
            return this.httpClient.get<IndexData>('/v1/hostel/' + this.bootstrapService.getSubdomain())
                .pipe(map(data => ObjectMapper.deserialize(IndexData, data)))
                .toPromise();
        });
    }

}

@Component({
    selector: 'hostel-index',
    templateUrl: './index.component.html',
    providers: [BookingService]
})
export class IndexComponent implements OnInit {

    public data: IndexData;

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected ngZone: NgZone,
        protected globalConfig: GlobalConfig,
        public bootstrapData: BootstrapData,
        protected route: ActivatedRoute,
        protected router: Router,
        protected metaService: MetaService,
        protected bookingService: BookingService,
        protected httpClient: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.data = data.component;
            this.metaService.setDescription(this.data.page.description);
            this.metaService.setKeywords(this.data.page.keywords);
        });
        // Load testing
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.httpClient.get('/v2/bootstrap/' + this.bootstrapData.hostel.alias).toPromise().then(data => {
                    //console.log(data);
                }).catch(error => {
                    console.error(error);
                    throw new Error('Load API v2 error: ' + error.message);
                });
            }, 3000);
        }
    }

    public trackByRoom(index: number, room: HostelRoom): number {
        return room.id;
    }

    public trackByPromo(index: number, promo: IndexDataPromo): number {
        return promo.promoId;
    }

    public getPromoImg(promoId: number): string {
        return this.globalConfig.static.url + '/img/promos/' + (promoId + 1) + '.jpg';
    }

    public onBooking(roomId?: number): void {
        let options: { dateFrom?: string, dateTo?: string, roomId?: number } = {};
        if (this.bookingService.hasDateFrom()) {
            options.dateFrom = moment(this.bookingService.getDateFrom()).format('YYYY-MM-DD');
        }
        if (this.bookingService.hasDateTo()) {
            options.dateTo = moment(this.bookingService.getDateTo()).format('YYYY-MM-DD');
        }
        if (this.bootstrapData.hostel.booking.provider == 'likehostel') {
            if (roomId) {
                options.roomId = roomId;
                this.router.navigate(['/booking', options]);
            } else {
                this.router.navigate(['/'], {
                    fragment: 'rooms',
                    queryParams: options
                });
            }
        } else {
            this.router.navigate(['/booking', options]);
        }
    }

}

import { Component, DoCheck, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import './review.component.scss';
import { HostelReview } from "../hostel";
import { Nl2BrPipe } from "common/nl2br.pipe";
import { isPlatformServer } from "@angular/common";

@Component({
    selector: 'hostel-review',
    templateUrl: './review.component.html'
})
export class ReviewComponent implements OnInit, DoCheck {

    @Input('review')
    public review: HostelReview;

    @ViewChild('reviewText')
    public reviewText: ElementRef;

    public showFullText: boolean = null;

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected nl2Br: Nl2BrPipe
    ) {
    }

    public ngOnInit(): void {
        if (isPlatformServer(this.platformId)) {
            this.showFullText = true;
        }
    }

    public ngDoCheck(): void {
        let maxLength = 200;
        if (this.review && this.reviewText) {
            let text = this.nl2Br.transform(this.review.text);
            if (!this.showFullText && text.length > maxLength) {
                text = text.substr(0, maxLength) + '...';
                this.showFullText = false;
            }
            this.reviewText.nativeElement.innerHTML = text;
        }
    }

}

<div class="container mt-5">
    <h1 class="text-center">
        Мини-гостиницы европейского типа в городе {{ data.city.name }}
    </h1>
    <h2 class="text-center mt-5">Открытие 15 июня</h2>
    <h2 class="text-center">Забронируй и будь первым гостем!</h2>
</div>

<div class="container mt-5">
    <div class="row">
        <div *ngIf="!feedbackFormSubmitted" class="col-12 offset-0 col-lg-8 offset-lg-2">
            <form [formGroup]="feedbackForm" (ngSubmit)="onFeedbackSubmit()">
                <div class="form-group row">
                    <label for="form-feedback-name" class="col-md-2 col-form-label">Имя</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control" id="form-feedback-name" formControlName="name">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="form-feedback-phone" class="col-md-2 col-form-label">Телефон</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="+7"
                               id="form-feedback-phone" formControlName="phone">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="form-feedback-message" class="col-md-2 col-form-label">Сообщение</label>
                    <div class="col-md-10">
                        <textarea class="form-control" rows="3"
                                  id="form-feedback-message" formControlName="message"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="offset-md-2 col-md-10">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="form-feedback-privacy" formControlName="privacy">
                            <label class="form-check-label text-justify" for="form-feedback-privacy">
                                Я согласен на обработку персональных данных в соответствии с
                                <a routerLink="privacy" class="text-like">условиями политики конфиденциальности</a>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="offset-md-2 col-md-10">
                        <button type="submit" class="btn btn-like" [disabled]="!feedbackForm.valid">
                            Оставить заявку на бронирование
                        </button>
                        <small class="text-muted d-block mt-2">
                            Никакие из предоставленных Вами данных не будут переданы третьим лицам.
                        </small>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="feedbackFormSubmitted" class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
            <p class="h5">Ваша заявка отправлена!</p>
            <p class="h5">Наш менеджер свяжется с Вами в ближайшее время.</p>
        </div>
    </div>
</div>

<div class="mt-5 mb-5">
    <hostel-whats-in></hostel-whats-in>
</div>

<hostel-index-address [coordinates]="coordinates" [hostel]="bootstrapData.hostel"></hostel-index-address>
<city-description [city]="data.city"></city-description>

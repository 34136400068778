import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { IndexComponent, IndexResolver } from "./index/index.component";

const routes: Routes = [
    {
        path: '',
        component: IndexComponent,
        resolve: {
            component: IndexResolver
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    providers: [IndexResolver],
    exports: [
        RouterModule
    ]
})
export class CityRoutingModule {
}

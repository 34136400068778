import { enableProdMode, getModuleFactory, NgZone } from '@angular/core';
import {
    platformBrowser,
    TransferState,
    ɵangular_packages_platform_browser_platform_browser_f as initTransferState
} from '@angular/platform-browser';
import { NgModuleFactory } from "@angular/core/src/linker/ng_module_factory";
import { CityBrowserModule } from "./city/city.browser.module";
import { HostelBrowserModule } from "./hostel/hostel.browser.module";
import { NotFoundBrowserModule } from "./notfound/notfound.browser.module";
import './city/city.browser.module.ngfactory';
import './hostel/hostel.browser.module.ngfactory';
import './notfound/notfound.browser.module.ngfactory';
import './dummy/dummy.browser.module.ngfactory';
import { BootstrapData, BootstrapType } from "app/bootstrap/bootstrap.service";
import { GlobalConfig } from "app/bootstrap/global-config";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { BootstrapBrowserService } from "app/bootstrap/bootstrap.browser.service";
import { hideLoadingSpinner } from "app/loading-spinner";
import { BootstrapTypeSerializerDeserializer } from "app/bootstrap/bootstrap.service";
import * as Raven from 'raven-js';
import { errorHandler } from "app/error/error";
import 'app/vendor.browser';
import { DummyBrowserModule } from "./dummy/dummy.browser.module";

function bootstrap(type: BootstrapType, globalConfig: GlobalConfig, bootstrapService: BootstrapBrowserService, transferState: TransferState, transferStateService: TransferStateService): void {
    let factory: NgModuleFactory<CityBrowserModule | HostelBrowserModule | NotFoundBrowserModule | DummyBrowserModule> = null;
    switch (type) {
        case BootstrapType.LIKEHOSTEL:
            if (APP_CONFIG.browser.sentry.enabled) {
                Raven.setTagsContext({app: 'likehostel'});
            }
            throw new Error('Incorrect bootstrap type "likehostel". Use likehostel app.');
        case BootstrapType.CITY:
            if (APP_CONFIG.browser.sentry.enabled) {
                Raven.setTagsContext({app: 'city'});
            }
            factory = getModuleFactory('city-browser');
            break;
        case BootstrapType.HOSTEL:
            if (APP_CONFIG.browser.sentry.enabled) {
                Raven.setTagsContext({app: 'hostel'});
            }
            factory = getModuleFactory('hostel-browser');
            break;
        case BootstrapType.DUMMY:
            if (APP_CONFIG.browser.sentry.enabled) {
                Raven.setTagsContext({app: 'dummy'});
            }
            factory = getModuleFactory('dummy-browser');
            break;
        default:
        case BootstrapType.NOT_FOUND:
            if (APP_CONFIG.browser.sentry.enabled) {
                Raven.setTagsContext({app: 'notfound'});
            }
            factory = getModuleFactory('notfound-browser');
            break;
    }
    let ngZone = new NgZone({});
    ngZone.onError.subscribe((error: any) => {
        errorHandler(error);
    });
    platformBrowser([
        {provide: 'http_hostname', useValue: window.document.location.hostname},
        {provide: 'bootstrap-service', useValue: bootstrapService},
        {provide: GlobalConfig, useValue: globalConfig},
        {provide: TransferState, useValue: transferState},
        {provide: TransferStateService, useValue: transferStateService}
    ]).bootstrapModuleFactory(factory, {
        ngZone: ngZone
    }).then((moduleRef) => {
        hideLoadingSpinner();
        let bootstrapData = moduleRef.injector.get(BootstrapData);
        let typeString = new BootstrapTypeSerializerDeserializer().serialize(bootstrapData.type);
        console.info(
            'Started "' + typeString + '" app v' + APP_VERSION + '.',
            'WebAPI v' + bootstrapData.version
        );
    }).catch((error) => {
        errorHandler(error);
    });
}

export function startLikeHostelAppHostel(): void {
    if (APP_MODE == 'production') {
        enableProdMode();
    }

    let transferState: TransferState = null;
    let transferStateService: TransferStateService = null;
    let stateScript = jQuery('body').find('script#hostel-state, script#city-state, script#hostel-notfound-state, script#dummy-state').get(0);
    if (stateScript) {
        let appId = stateScript.id.substr(0, stateScript.id.length - 6);
        transferState = initTransferState(window.document, appId);
        transferStateService = new TransferStateService(transferState, appId, window.document);
    }
    let globalConfig = new GlobalConfig();
    let bootstrapService = new BootstrapBrowserService(globalConfig, transferStateService);
    bootstrapService.getType().then(function (type) {
        bootstrap(type, globalConfig, bootstrapService, transferState, transferStateService);
    }).catch(function (error) {
        errorHandler(error);
    });
}

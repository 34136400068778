import { JsonProperty } from "json-object-mapper";

export class Hostel {
}

export class HostelReview {
    @JsonProperty()
    public readonly id: number;

    @JsonProperty()
    public readonly author: string;

    @JsonProperty()
    public readonly source: string;

    @JsonProperty()
    public readonly text: string;
}

export class HostelRoomPhoto {

    @JsonProperty()
    public readonly full: string;

    @JsonProperty()
    public readonly preview: string;

    @JsonProperty()
    public readonly small: string;
}

export class HostelRoom {

    @JsonProperty()
    public readonly id: number;

    @JsonProperty()
    public readonly name: string;

    @JsonProperty()
    public readonly price: number;

    @JsonProperty()
    public readonly description: string;

    @JsonProperty()
    public readonly photos: HostelRoomPhoto[];
}

import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { HttpClient } from "@angular/common/http";
import { BootstrapData, BootstrapService } from "app/bootstrap/bootstrap.service";
import { map } from "rxjs/operators";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { MetaService } from "app/bootstrap/meta.service";
import { City } from "city/city";
import './index.component.scss';
import { FormControl, FormGroup, Validators } from "@angular/forms";

export class IndexDataPage {

    @JsonProperty()
    public readonly description: string;

    @JsonProperty()
    public readonly keywords: string;

}

export class IndexData {
    @JsonProperty({type: IndexDataPage})
    public readonly page: IndexDataPage;

    @JsonProperty({type: City})
    public readonly city: City;
}

@Injectable()
export class IndexResolver implements Resolve<IndexData> {

    protected readonly TRANSFER_STATE_KEY = 'DummyIndexResolverData';

    public constructor(
        protected bootstrapService: BootstrapService,
        protected httpClient: HttpClient,
        protected transfaerStateService: TransferStateService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IndexData> {
        return this.transfaerStateService.get<IndexData>(this.TRANSFER_STATE_KEY, () => {
            return this.httpClient.get<IndexData>('/v1/city/' + this.bootstrapService.getSubdomain() + '-city')
                .pipe(map(data => ObjectMapper.deserialize(IndexData, data)))
                .toPromise();
        });
    }

}

@Component({
    selector: 'dummy-index',
    templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {
    public data: IndexData;
    public coordinates = {
        latitude: 45.360139,
        longitude: 36.470253
    };
    public feedbackForm = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        phone: new FormControl(null, [Validators.required]),
        message: new FormControl(null, [Validators.required]),
        privacy: new FormControl(null, [Validators.required])
    });
    public feedbackFormSubmitted: boolean = false;

    public constructor(
        protected route: ActivatedRoute,
        protected metaService: MetaService,
        public bootstrapData: BootstrapData,
        protected httpClient: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.data = data.component;
            this.metaService.setDescription(this.data.page.description);
            this.metaService.setKeywords(this.data.page.keywords);
        });
    }

    public onFeedbackSubmit(): void {
        this.feedbackForm.disable();
        this.httpClient.post('/v1/feedback', {
            hostel_id: this.bootstrapData.hostel.hostelId,
            name: this.feedbackForm.get('name').value,
            phone: this.feedbackForm.get('phone').value,
            message: this.feedbackForm.get('message').value,
            privacy: this.feedbackForm.get('privacy').value
        }).subscribe(() => {
            this.feedbackFormSubmitted = true;
        }, (error) => {
            console.error("Submit dummy feedback form error", error);
            this.feedbackForm.enable();
        });
    }

}

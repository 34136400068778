import {
    AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, Output, PLATFORM_ID,
    ViewChild
} from "@angular/core";
import './room.component.scss';
import { HostelRoom, HostelRoomPhoto } from "../hostel";
import { Nl2BrPipe } from "common/nl2br.pipe";
import { GlobalConfig } from "app/bootstrap/global-config";
import { isPlatformBrowser } from "@angular/common";

@Component({
    selector: 'hostel-room',
    templateUrl: './room.component.html'
})
export class RoomComponent implements OnChanges, AfterViewInit {

    @Input('room')
    public room: HostelRoom;

    @ViewChild('roomDescription')
    public roomDescription: ElementRef;

    @ViewChild('photosCarousel')
    public photosCarousel: ElementRef;

    private _photosCarouselFullScreen: boolean = false;

    public set photosCarouselFullScreen(fullscreen: boolean) {
        if (fullscreen) {
            jQuery('body').addClass('overflow-hidden');
        } else {
            jQuery('body').removeClass('overflow-hidden');
        }
        this._photosCarouselFullScreen = fullscreen;
    }

    public get photosCarouselFullScreen(): boolean {
        return this._photosCarouselFullScreen;
    }

    @Output('booking')
    public readonly booking: EventEmitter<number> = new EventEmitter<number>();

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected ngZone: NgZone,
        protected globalConfig: GlobalConfig,
        protected nl2Br: Nl2BrPipe
    ) {
    }

    public ngOnChanges(): void {
        let description = (this.room && this.room.description) ? this.nl2Br.transform(this.room.description) : '';
        this.roomDescription.nativeElement.innerHTML = description;
    }

    public trackByPhoto(index: number, photo: HostelRoomPhoto): string {
        return photo.full;
    }

    public getRoomImg(url: string): string {
        return this.globalConfig.content.url + url;
    }

    public showPhotoSlide(number: number): void {
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.photosCarousel.nativeElement).carousel(number);
        });
    }

    public getPhotoSlideCurrentNumber(): number {
        if (!isPlatformBrowser(this.platformId)) {
            return 0;
        }
        let inner = jQuery(this.photosCarousel.nativeElement).children('.carousel-inner');
        let number = inner.children('.carousel-item-next, .carousel-item-prev').index();
        if (number > -1) {
            return number;
        }
        return inner.children('.active').index();
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.ngZone.runOutsideAngular(() => {
                jQuery(this.photosCarousel.nativeElement).carousel({
                    interval: 0
                });
            });
        }
    }

    public onBooking(): void {
        this.booking.emit(this.room.id);
    }

}

import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { NavbarService } from "layout/navbar/navbar.service";
import { ComponentWithRouterOutlet } from "app/component-with-router-outlet";

@Component({
    selector: 'app-root',
    templateUrl: 'notfound.component.html'
})
export class NotFoundComponent implements OnInit, ComponentWithRouterOutlet {

    @ViewChild('routerOutlet', {read: ViewContainerRef})
    public routerOutlet: ViewContainerRef;

    public constructor(
        protected navbarService: NavbarService
    ) {
    }

    public ngOnInit(): void {
        this.navbarService.setDefaultOptions({
            useAbsoluteLinks: true
        });
    }

}

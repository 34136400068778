import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FooterService } from "layout/footer/footer.service";
import { GlobalConfig } from "app/bootstrap/global-config";
import { BootstrapData } from "app/bootstrap/bootstrap.service";
import { Title } from "@angular/platform-browser";
import { CallmeService } from "hostel/callme/callme.service";
import { NavbarService } from "layout/navbar/navbar.service";
import { ComponentWithRouterOutlet } from "app/component-with-router-outlet";

@Component({
    selector: 'app-root',
    templateUrl: 'hostel.component.html'
})
export class HostelComponent implements OnInit, ComponentWithRouterOutlet {

    @ViewChild('routerOutlet', {read: ViewContainerRef})
    public routerOutlet: ViewContainerRef;

    public constructor(
        protected globalConfig: GlobalConfig,
        protected bootstrapData: BootstrapData,
        protected titleService: Title,
        public footerService: FooterService,
        protected navbarService: NavbarService,
        protected callmeService: CallmeService
    ) {
    }

    public ngOnInit(): void {
        this.titleService.setTitle(this.bootstrapData.hostel.title);

        this.navbarService.setDefaultOptions({
            useAbsoluteLinks: false,
            phone: this.bootstrapData.hostel.phone
        });

        this.footerService.callme.subscribe(() => {
            this.callmeService.open();
        });
        let footerOptions = this.footerService.getDefaultOptions();
        footerOptions.backgroundImage = this.globalConfig.content.url + this.bootstrapData.hostel.photo;
        footerOptions.phone = this.bootstrapData.hostel.phone;
        if (this.bootstrapData.hostel.links.facebook) {
            footerOptions.links.facebook = this.bootstrapData.hostel.links.facebook;
        }
        if (this.bootstrapData.hostel.links.vkontakte) {
            footerOptions.links.vkontakte = this.bootstrapData.hostel.links.vkontakte;
        }
        if (this.bootstrapData.hostel.links.twitter) {
            footerOptions.links.twitter = this.bootstrapData.hostel.links.twitter;
        }
        if (this.bootstrapData.hostel.links.instagram) {
            footerOptions.links.instagram = this.bootstrapData.hostel.links.instagram;
        }
        this.footerService.setDefaultOptions(footerOptions);
    }

}

<form [formGroup]="bookingForm" (ngSubmit)="bookingFormSubmit.emit()"
      class="text-center d-block mt-5">
    <input type="text" formControlName="dateFrom" #bookingFormDateFrom
           class="form-control" placeholder="Дата заезда"
           [datepicker]="datepickerFromOptions"
           [startDate]="datepickerFromOptions.startDate" [endDate]="datepickerFromOptions.endDate">
    <input type="text" formControlName="dateTo" #bookingFormDateTo
           class="form-control" placeholder="Дата выезда"
           [datepicker]="datepickerToOptions"
           [startDate]="datepickerToOptions.startDate" [endDate]="datepickerToOptions.endDate">
    <button type="submit" class="btn btn-like">Забронировать номер</button>
</form>

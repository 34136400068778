import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import './wubook.component.scss';
import { BookingService } from "./booking.service";
import * as moment from "moment";
import { BootstrapData } from "app/bootstrap/bootstrap.service";

@Component({
    selector: 'hostel-wubook',
    templateUrl: './wubook.component.html'
})
export class WubookComponent implements OnInit {

    @ViewChild('wubookFrame')
    public wubookFrame: ElementRef;

    public constructor(
        protected bootstrapData: BootstrapData,
        protected bookingService: BookingService
    ) {
    }

    public ngOnInit(): void {
        let wubookFrame: HTMLIFrameElement = this.wubookFrame.nativeElement;
        let wubookFrameSrc = 'https://wubook.net/wbkd/wbk/?lcode=' + this.bootstrapData.hostel.booking.wubook.code;
        if (this.bookingService.hasDateFrom()) {
            wubookFrameSrc = wubookFrameSrc + '&dfrom=' + moment(this.bookingService.getDateFrom()).format('DD/MM/YYYY');
        }
        if (this.bookingService.hasDateTo()) {
            wubookFrameSrc = wubookFrameSrc + '&dto=' + moment(this.bookingService.getDateTo()).format('DD/MM/YYYY');
        }
        if (this.bookingService.hasPromoCode()) {
            wubookFrameSrc = wubookFrameSrc + '&dcode=' + this.bookingService.getPromoCode();
        }
        wubookFrame.src = wubookFrameSrc;
    }

}

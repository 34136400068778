import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    NgZone,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import './booking-form.component.scss';
import { BookingService } from "hostel/booking/booking.service";

@Component({
    selector: 'hostel-booking-form',
    templateUrl: './booking-form.component.html'
})
export class BookingFormComponent implements OnInit {

    @ViewChild('bookingFormDateFrom')
    public bookingFormDateFrom: ElementRef;

    @ViewChild('bookingFormDateTo')
    public bookingFormDateTo: ElementRef;

    public readonly bookingForm = new FormGroup({
        dateFrom: new FormControl(),
        dateTo: new FormControl()
    });

    public datepickerFromOptions: any = {
        autoclose: true,
        language: 'ru',
        startDate: new Date(),
        endDate: null,
        todayHighlight: true,
        orientation: 'bottom',
        zIndexOffset: 20
    };

    public datepickerToOptions = Object.assign({}, this.datepickerFromOptions, {
        startDate: moment().add(1, 'd').toDate()
    });

    @Output('bookingFormSubmit')
    public bookingFormSubmit: EventEmitter<void> = new EventEmitter<void>();

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected ngZone: NgZone,
        protected bookingService: BookingService
    ) {
    }

    public ngOnInit(): void {
        this.bookingForm.get('dateFrom').valueChanges.subscribe((date: string) => {
            let dateFrom = moment(date, ["DD.MM.YYYY", 'YYYY-MM-DD'], true);
            let dateTo = moment(this.bookingForm.get('dateTo').value, ['DD.MM.YYYY', 'YYYY-MM-DD']);
            if (dateFrom.isValid()) {
                this.bookingService.setDateFrom(dateFrom);
            } else {
                this.bookingService.clearDateFrom();
            }
            if (dateTo.isValid() && dateTo.isSameOrBefore(dateFrom)) {
                this.bookingForm.get('dateTo').setValue(null);
            }
        });
        this.bookingForm.get('dateTo').valueChanges.subscribe((date: string) => {
            let dateFrom = moment(this.bookingForm.get('dateFrom').value, ['DD.MM.YYYY', 'YYYY-MM-DD']);
            let dateTo = moment(date, ["DD.MM.YYYY", 'YYYY-MM-DD'], true);
            if (dateTo.isValid()) {
                this.bookingService.setDateTo(dateTo);
            } else {
                this.bookingService.clearDateTo();
            }
            if (dateFrom.isValid() && dateFrom.isSameOrAfter(dateTo)) {
                this.bookingForm.get('dateFrom').setValue(null);
            }
        });
    }

}

import { Component, ElementRef, Injectable, OnInit, SecurityContext, ViewChild } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { BootstrapData, BootstrapService } from "app/bootstrap/bootstrap.service";
import { MetaService } from "app/bootstrap/meta.service";
import { DomSanitizer } from "@angular/platform-browser";
import './about.component.scss';
import { GlobalConfig } from "app/bootstrap/global-config";
import { map } from "rxjs/operators";

export class AboutDataPage {

    @JsonProperty()
    public readonly title: string;

    @JsonProperty()
    public readonly description: string;

    @JsonProperty()
    public readonly keywords: string;

}

export class AboutDataCityHostel {
    @JsonProperty({name: 'id'})
    public readonly hostelId: number;

    @JsonProperty()
    public readonly name: string;

    @JsonProperty()
    public readonly alias: string;
}

export class AboutDataCity {
    @JsonProperty({type: AboutDataCityHostel})
    public readonly hostels: AboutDataCityHostel[];
}

export class AboutData {

    @JsonProperty({type: AboutDataPage})
    public readonly page: AboutDataPage;

    @JsonProperty()
    public readonly description: string;

    @JsonProperty({type: AboutDataCity})
    public readonly city: AboutDataCity;

}

@Injectable()
export class AboutResolver implements Resolve<AboutData> {

    protected readonly TRANSFER_STATE_KEY = 'HostelAboutResolverData';

    public constructor(
        protected bootstrapService: BootstrapService,
        protected httpClient: HttpClient,
        protected transfaerStateService: TransferStateService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<AboutData> {
        return this.transfaerStateService.get<AboutData>(this.TRANSFER_STATE_KEY, () => {
            return this.httpClient.get<AboutData>('/v1/hostel/' + this.bootstrapService.getSubdomain() + '/about')
                .pipe(map(data => ObjectMapper.deserialize(AboutData, data)))
                .toPromise();
        });
    }

}

@Component({
    selector: 'hostel-about',
    templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {

    public data: AboutData;

    @ViewChild('hostelDescription')
    public hostelDescription: ElementRef;

    public constructor(
        protected metaService: MetaService,
        protected route: ActivatedRoute,
        protected sanitizer: DomSanitizer,
        public bootstrapData: BootstrapData,
        protected globalConfig: GlobalConfig
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.data = data.component;
            this.metaService.setDescription(this.data.page.description);
            this.metaService.setKeywords(this.data.page.keywords);
            this.hostelDescription.nativeElement.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.data.description);
        });
    }

    public trackByHostel(index: number, hostel: AboutDataCityHostel): number {
        return hostel.hostelId;
    }

    public getHostelUrl(alias: string): string {
        // @todo: temporary transition url. Fix in next major release
        //return this.globalConfig.deploy.scheme + '://' + alias + '.' + this.globalConfig.deploy.domain;
        return 'http://' + alias + '.' + this.globalConfig.deploy.domain;
    }

    public getHostelImg(): string {
        return this.globalConfig.content.url + this.bootstrapData.hostel.photo;
    }

}

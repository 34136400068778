import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'maxlength', pure: false})
export class MaxlengthPipe implements PipeTransform {

    public transform(value: any[], maxlength: number): any {
        return value.slice(0, maxlength);
    }

}

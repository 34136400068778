<div class="city-description-img d-none d-sm-block" [style.background-image]="'url(' + getCityImg() + ')'">
    <h2 class="text-center">Немного о городе {{ city.name }}</h2>
</div>
<div class="d-sm-none mt-3 city-description-head">
    <h2 class="text-center">Немного о городе {{ city.name }}</h2>
    <img src="{{ getCityImg() }}" class="rounded float-right" alt="{{ city.name }}">
</div>
<div class="container pt-3">
    <div #cityDescription></div>
</div>

import { Component, ElementRef, Injectable, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { BootstrapService } from "app/bootstrap/bootstrap.service";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { MetaService } from "app/bootstrap/meta.service";
import './index.component.scss';
import { GlobalConfig } from "app/bootstrap/global-config";
import { YandexMapsService } from "yandex-maps/yandex-maps.service";
import { City } from "../city";
import { HostelReview } from "hostel/hostel";
import { map } from "rxjs/operators";

export class IndexDataHostelCoordinates {
    @JsonProperty()
    public readonly latitude: number;

    @JsonProperty()
    public readonly longitude: number;
}

export class IndexDataHostel {

    @JsonProperty()
    public readonly id: number;

    @JsonProperty()
    public readonly alias: string;

    @JsonProperty()
    public readonly name: string;

    @JsonProperty()
    public readonly address: string;

    @JsonProperty()
    public readonly phone: string;

    @JsonProperty({name: 'cheapest_price'})
    public readonly cheapestPrice: number;

    @JsonProperty()
    public readonly shorten: string;

    @JsonProperty()
    public readonly img: string;

    @JsonProperty({type: IndexDataHostelCoordinates})
    public readonly coordinates: IndexDataHostelCoordinates;
}

export class IndexDataPage {

    @JsonProperty()
    public readonly description: string;

    @JsonProperty()
    public readonly keywords: string;

}

export class IndexData {

    @JsonProperty({type: IndexDataPage})
    public readonly page: IndexDataPage;

    @JsonProperty({type: City})
    public readonly city: City;

    @JsonProperty({type: IndexDataHostel})
    public readonly hostels: IndexDataHostel[];

    @JsonProperty({type: HostelReview})
    public readonly reviews: HostelReview[];

}

@Injectable()
export class IndexResolver implements Resolve<IndexData> {

    protected readonly TRANSFER_STATE_KEY = 'CityIndexResolverData';

    public constructor(
        protected bootstrapService: BootstrapService,
        protected httpClient: HttpClient,
        protected transfaerStateService: TransferStateService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IndexData> {
        return this.transfaerStateService.get<IndexData>(this.TRANSFER_STATE_KEY, () => {
            return this.httpClient.get<IndexData>('/v1/city/' + this.bootstrapService.getSubdomain())
                .pipe(map(data => ObjectMapper.deserialize(IndexData, data)))
                .toPromise();
        });
    }

}

@Component({
    selector: 'city-index',
    templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit, OnDestroy {

    public data: IndexData;

    @ViewChild('cityMap')
    public cityMap: ElementRef;

    protected cityYaMap: ymaps.Map;

    public constructor(
        protected ngZone: NgZone,
        protected globalConfig: GlobalConfig,
        protected route: ActivatedRoute,
        protected metaService: MetaService,
        protected yaMapsService: YandexMapsService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.data = data.component;
            this.metaService.setDescription(this.data.page.description);
            this.metaService.setKeywords(this.data.page.keywords);
        });
        this.ngZone.runOutsideAngular(() => {
            this.yaMapsService.createMap(this.cityMap, {
                center: [55.76, 37.64],
                zoom: 7,
                controls: ['geolocationControl', 'zoomControl']
            }).then((map) => {
                this.cityYaMap = map;
                for (let hostel of this.data.hostels) {
                    let hostelPlacemark = new ymaps.Placemark(
                        [hostel.coordinates.latitude, hostel.coordinates.longitude],
                        {
                            hintContent: hostel.address,
                            balloonContentHeader: hostel.name,
                            balloonContentBody: hostel.address + '<br>' + hostel.phone
                        },
                        {
                            preset: 'islands#redHotelIcon'
                        }
                    );
                    map.geoObjects.add(hostelPlacemark);
                    hostelPlacemark.events.add('click', () => {
                        map.setCenter(
                            [hostel.coordinates.latitude, hostel.coordinates.longitude],
                            17,
                            {
                                duration: 500
                            }
                        );
                    });
                }
                map.setBounds(map.geoObjects.getBounds());
                if (map.getZoom() > 15) {
                    map.setZoom(15);
                }
            });
        });
    }

    public ngOnDestroy(): void {
        if (this.cityYaMap) {
            this.cityYaMap.destroy();
        }
    }

    public trackByHostel(index: number, hostel: IndexDataHostel): number {
        return hostel.id;
    }

    public getHostelImg(hostel: IndexDataHostel): string {
        return this.globalConfig.content.url + hostel.img;
    }

    public getHostelUrl(hostel: IndexDataHostel): string {
        // @todo: temporary transition url. Fix in next major release
        //return this.globalConfig.deploy.scheme + '://' + hostel.alias + '.' + this.globalConfig.deploy.domain;
        return 'http://' + hostel.alias + '.' + this.globalConfig.deploy.domain;
    }

}

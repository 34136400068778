import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { IndexComponent } from "./index/index.component";
import { HostelCommonModule } from "./hostel-common.module";
import { CityCommonModule } from "city/city-common.module";
import { HostelRoutingModule } from "./hostel-routing.module";
import { YandexMapsModule } from "yandex-maps/yandex-maps.module";
import { RoomComponent } from "./room/room.component";
import { CommonModule as AppCommonModule } from 'common/common.module'
import { CallmeComponent } from "./callme/callme.component";
import { CallmeService } from "./callme/callme.service";
import { WubookComponent } from "./booking/wubook.component";
import { BookingComponent } from "./booking/booking.component";
import { BnovoComponent } from "./booking/bnovo.component";
import { BookingFormComponent as IndexBookingFormComponent } from "./index/booking-form.component";
import { AboutComponent } from "./about/about.component";
import { CarouselComponent as IndexCarouselComponent } from "./index/carousel.component";
import { OtaWidgetsComponent } from "./index/ota-widgets.component";

@NgModule({
    imports: [
        CommonModule,
        HostelRoutingModule,
        AppCommonModule,
        HostelCommonModule,
        CityCommonModule,
        YandexMapsModule,
        ReactiveFormsModule
    ],
    providers: [
        CallmeService
    ],
    declarations: [
        IndexComponent, IndexCarouselComponent, IndexBookingFormComponent,
        OtaWidgetsComponent,
        RoomComponent,
        CallmeComponent,
        BookingComponent, WubookComponent, BnovoComponent,
        AboutComponent
    ],
    exports: [
        CallmeComponent
    ]
})
export class HostelModule {
}

import { ElementRef, Inject, Injectable, NgZone } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { GlobalConfig } from "app/bootstrap/global-config";

@Injectable()
export class YandexMapsService {

    public constructor(
        @Inject(DOCUMENT) protected document: HTMLDocument,
        protected ngZone: NgZone,
        protected globalConfig: GlobalConfig
    ) {
    }

    public createMap(element: ElementRef, state: ymaps.IMapState, options?: ymaps.IMapOptions): Promise<ymaps.Map> {
        return new Promise<ymaps.Map>((resolve) => {
            let onYmapsReady = () => {
                this.ngZone.runOutsideAngular(() => {
                    let map = new ymaps.Map(element.nativeElement, state, options);
                    resolve(map);
                });
            };
            if (typeof ymaps != 'object') {
                this.resolveScript().addEventListener('load', () => {
                    ymaps.ready(() => onYmapsReady());
                });
            } else {
                ymaps.ready(() => onYmapsReady());
            }
        });
    }

    protected resolveScript(): HTMLScriptElement {
        let src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
        if (this.globalConfig.isDevelopmentMode()) {
            src = src + '&mode=debug';
        }
        let script: HTMLScriptElement = null;
        let docScripts = this.document.getElementsByTagName('script');
        for (let i = docScripts.length; i--;) {
            if (docScripts[i].src == src) {
                script = docScripts[i];
                break;
            }
        }
        if (!script) {
            script = this.document.createElement('script');
            script.async = true;
            script.src = src;
            script.type = 'text/javascript';
            this.document.body.appendChild(script);
        }
        return script;
    }

}

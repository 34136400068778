import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class CallmeService {

    protected opened: boolean = false;

    public title: string;

    public open(title?: string): void {
        if (!title) {
            title = 'Мы перезвоним Вам!';
        }
        this.title = title;
        let previous = this.opened;
        this.opened = true;
        if (previous != true) {
            this.onOpen.emit();
        }
    }

    public close(): void {
        let previous = this.opened;
        this.opened = false;
        if (previous != false) {
            this.onClose.emit();
        }
    }

    public readonly onOpen = new EventEmitter<void>();
    public readonly onClose = new EventEmitter<void>();

    public isOpened(): boolean {
        return this.opened;
    }

    public isClosed(): boolean {
        return !this.isOpened();
    }

}

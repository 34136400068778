<div class="font-awesome-5-free-preload">.</div>
<div class="card">
    <div class="card-body">
        <p class="float-right mb-0 h3 hostel-room-price">
            <span class="badge badge-like">{{ room.price }}₽</span>
        </p>
        <div [class]="photosCarouselFullScreen ? 'room-carousel-fullscreen' : 'card-room-carousel'">
            <button *ngIf="photosCarouselFullScreen" type="button" class="close" aria-label="Close"
                    (click)="photosCarouselFullScreen = false">
                <span aria-hidden="true">&times;</span>
            </button>
            <div #photosCarousel class="carousel slide" (click)="photosCarouselFullScreen = true">
                <div class="carousel-inner">
                    <div *ngFor="let photo of room.photos; trackBy: trackByPhoto; first as isFirst"
                         class="carousel-item"
                         [class.active]="isFirst">
                        <img [src]="photosCarouselFullScreen ? getRoomImg(photo.full) : getRoomImg(photo.preview)"
                             alt="{{ room.name }}" class="img-spinner">
                    </div>
                </div>
            </div>
            <ol class="carousel-indicators-thumb">
                <li *ngFor="let photo of room.photos | maxlength: 4; let index = index"
                    [class.active]="getPhotoSlideCurrentNumber() == index">
                    <img src="{{ getRoomImg(photo.small) }}" alt="{{ room.name }}"
                         (click)="showPhotoSlide(index)"
                         class="img-spinner">
                </li>
            </ol>
        </div>
        <div class="card-room-description pt-3 pt-md-0">
            <h3 class="card-title">{{ room.name }}</h3>
            <p class="card-text" #roomDescription></p>
        </div>
        <div class="card-room-booking">
            <button (click)="onBooking()" type="button" class="btn btn-like">Забронировать</button>
        </div>
    </div>
</div>

<ol class="carousel-indicators-switchers">
    <li *ngFor="let photo of room.photos; let index = index"
        [class.active]="getPhotoSlideCurrentNumber() == index"
        (click)="showPhotoSlide(index)"
        class="d-none d-md-inline-block">
    </li>
</ol>

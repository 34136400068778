<h2 class="mb-3">Отзывы из сетей</h2>
<div class="row">
    <hostel-review *ngFor="let review of reviews | maxlength: reviewsMaxlength; trackBy: trackByReview"
                       [review]="review"
                       class="col-12 col-lg-6 mb-3"
    ></hostel-review>
</div>
<a *ngIf="reviews.length > reviewsMaxlength"
   class="btn btn-link text-muted text-center d-block"
   (click)="reviewsMaxlength = reviewsMaxlength + 6"
>показать больше отзывов</a>

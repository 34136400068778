import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import './city.less';
import { IndexComponent } from "./index/index.component";
import { YandexMapsModule } from "yandex-maps/yandex-maps.module";
import { CityCommonModule } from "./city-common.module";
import { CityRoutingModule } from "./city-routing.module";
import { HostelCommonModule } from "hostel/hostel-common.module";

@NgModule({
    imports: [
        CommonModule,
        CityRoutingModule,
        CityCommonModule,
        YandexMapsModule,
        HostelCommonModule
    ],
    declarations: [
        IndexComponent
    ],
    exports: []
})
export class CityModule {
}

import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FooterService } from "layout/footer/footer.service";
import { GlobalConfig } from "app/bootstrap/global-config";
import { BootstrapData } from "app/bootstrap/bootstrap.service";
import { NavbarService } from "layout/navbar/navbar.service";
import { ComponentWithRouterOutlet } from "app/component-with-router-outlet";

@Component({
    selector: 'app-root',
    templateUrl: 'city.component.html'
})
export class CityComponent implements OnInit, ComponentWithRouterOutlet {

    @ViewChild('routerOutlet', {read: ViewContainerRef})
    public routerOutlet: ViewContainerRef;

    public constructor(
        protected globalConfig: GlobalConfig,
        protected bootstrapData: BootstrapData,
        public footerService: FooterService,
        protected titleService: Title,
        protected navbarService: NavbarService
    ) {
    }

    public ngOnInit(): void {
        this.titleService.setTitle(this.bootstrapData.city.title);

        this.navbarService.setDefaultOptions({
            useAbsoluteLinks: true
        });

        let footerOptions = this.footerService.getDefaultOptions();
        footerOptions.backgroundImage = this.globalConfig.static.url + '/img/homescreen-v1.jpg';
        this.footerService.setDefaultOptions(footerOptions);
    }

}

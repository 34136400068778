<div class="hostel-about-image mb-5" [style.background-image]="'url(' + getHostelImg() + ')'">
    <div class="container">
        <h1>{{ bootstrapData.hostel.name }}</h1>
    </div>
</div>
<div class="container">
    <a *ngFor="let hostel of data.city.hostels; trackBy: trackByHostel"
       class="btn btn-like btn-block"
       [href]="getHostelUrl(hostel.alias)">
        {{ hostel.name }}
    </a>
    <div class="mt-5" #hostelDescription></div>
</div>

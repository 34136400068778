import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule, BrowserTransferStateModule } from "@angular/platform-browser";
import { BootstrapModule } from "app/bootstrap/bootstrap.module";
import { AnalyticsModule } from "analytics/analytics.module";
import { LayoutModule } from "layout/layout.module";
import { FeedbackModule } from "feedback/feedback.module";
import { Error404Module } from "error404/error404.module";
import { BootstrapService } from "app/bootstrap/bootstrap.service";
import { DummyRoutingModule } from "./dummy-routing.module";
import { DummyComponent } from "./dummy.component";
import { DummyModule } from "dummy/dummy.module";

@NgModule({
    id: 'dummy-browser',
    imports: [
        BrowserModule.withServerTransition({appId: 'dummy'}),
        BrowserTransferStateModule,
        CommonModule,
        BootstrapModule, LayoutModule, AnalyticsModule, FeedbackModule,
        DummyRoutingModule,
        DummyModule,
        Error404Module
    ],
    providers: [
        BootstrapService,
        {provide: 'bootstrap-service', useExisting: BootstrapService}
    ],
    declarations: [
        DummyComponent
    ],
    bootstrap: [DummyComponent]
})
export class DummyBrowserModule {
}

<div class="container">
    <h2 class="mb-3">Контакты и адреса</h2>
</div>
<div class="hostel-contact-map" #contactMap>
    <div class="card" #contactCard>
        <div class="card-body">
            <p class="card-text">
                <i class="fas fa-map-marker-alt"></i>
                {{ hostel.address }}
            </p>
            <p class="card-text">
                <i class="fas fa-phone"></i>
                {{ hostel.phone }}
            </p>
        </div>
    </div>
</div>

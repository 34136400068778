<div class="card">
    <div class="card-body">
        <i *ngIf="review.source == 'vk'" class="fab fa-vk"></i>
        <i *ngIf="review.source == 'fb'" class="fab fa-facebook-f"></i>
        <h5 class="card-title">{{ review.author }}</h5>
        <p class="card-text" #reviewText></p>
        <a *ngIf="showFullText === false"
           class="card-link text-muted text-center d-block"
           (click)="showFullText = true"
        >читать полностью</a>
    </div>
</div>

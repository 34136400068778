<div class="carousel hostel-index-carousel slide" #photosCarousel>
    <div class="carousel-inner">
        <div *ngFor="let photo of photos; trackBy: trackByPhoto; first as isFirst"
             class="carousel-item" [class.active]="isFirst">
            <img [src]="getPhotoImg(photo.full)" alt="{{ promoTitle }}" class="img-spinner">
        </div>
    </div>
    <ol class="carousel-indicators-thumb">
        <li *ngFor="let photo of photos; trackBy: trackByPhoto; let index = index"
            [class.active]="getPhotoSlideCurrentNumber() == index">
            <img src="{{ getPhotoImg(photo.small) }}" alt="{{ promoTitle }}" class="img-spinner"
                 (click)="showPhotoSlide(index)">
        </li>
    </ol>
    <div class="hostel-index">
        <div class="container">
            <h1 class="text-center">{{ promoTitle }}</h1>
            <hostel-booking-form (bookingFormSubmit)="bookingFormSubmit.emit()"></hostel-booking-form>
        </div>
    </div>
</div>

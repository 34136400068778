<div class="container mt-5">
    <h1 class="text-center">
        Мини-гостиницы европейского типа в городе {{ data.city.name }}
    </h1>
</div>

<div class="city-hostels mt-5">
    <div class="row m-0">
        <div class="col-12 col-xl-7 pt-2 pb-4 col-lg-10">
            <div *ngFor="let hostel of data.hostels; trackBy: trackByHostel" class="card d-block mt-3">
                <div class="city-hostel-img"
                     [style.background-image]="'url(' + getHostelImg(hostel) + ')'"></div>
                <div class="city-hostel-shorten">{{ hostel.shorten }}</div>
                <div class="card-body">
                    <div class="card-text">
                        <p>
                            г. {{ data.city.name }},
                            {{ hostel.address }}
                        </p>
                        <p class="city-hostel-phone">{{ hostel.phone }}</p>
                    </div>
                    <h2 class="float-right"><span class="badge badge-like">
                        от {{ hostel.cheapestPrice }}₽
                    </span></h2>
                    <a href="{{ getHostelUrl(hostel) }}" class="btn btn-like">Перейти к бронированию</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-5 p-0 city-hostles-map" #cityMap></div>
    </div>
</div>

<city-description [city]="data.city"></city-description>

<div *ngIf="data.reviews.length > 0" class="container mt-5 mb-5">
    <hostel-reviews-list [reviews]="data.reviews"></hostel-reviews-list>
</div>

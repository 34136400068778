import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
    BaseRouterErrorHandler,
    initRouterErrorHandler,
    ROUTER_ERROR_HANDLERS,
    routerErrorHandler
} from "app/router/error-handler";

const routes: Routes = [];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        errorHandler: routerErrorHandler
    })],
    providers: [
        {
            provide: ROUTER_ERROR_HANDLERS,
            useClass: BaseRouterErrorHandler,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initRouterErrorHandler,
            deps: [ROUTER_ERROR_HANDLERS],
            multi: true
        }
    ],
    exports: [RouterModule]
})
export class HostelRoutingModule {
}

import { Component } from "@angular/core";
import './navbar.scss';
import './mini-navbar.component.scss';
import { NavbarService } from "./navbar.service";

@Component({
    selector: 'mini-navbar',
    templateUrl: './mini-navbar.component.html'
})
export class MiniNavbarComponent {
    public constructor(
        public navbarService: NavbarService
    ) {
    }
}

import { AbstractBootstrapService, BootstrapData } from "./bootstrap.service";
import { ObjectMapper } from "json-object-mapper";

export class BootstrapBrowserService extends AbstractBootstrapService {

    protected loadData(): Promise<BootstrapData> {
        return new Promise<BootstrapData>((resolve, reject) => {
            jQuery.getJSON(this.getBootstrapUrl())
                .done(function (data: any) {
                    resolve(ObjectMapper.deserialize(BootstrapData, data));
                })
                .fail(function (jqxhr, textStatus, error) {
                    reject(new Error('Load bootstrap data error: ' + (error || jqxhr.statusText || textStatus)));
                });
        });
    }

    public getSubdomain(): string | null {
        return this.getSubdomainFromHostname(window.document.location.hostname);
    }

}
